import { FeathersError } from '@feathersjs/errors';
import { createAsyncAction } from 'typesafe-actions';

import { IGetLieuucationRequest } from '@api';
import { ILieuucation, ILieuucationTimestamped } from '@lieuu/core';

/*
 * GET
 */

export const getLieuucations = createAsyncAction(
  'lieuucation/GET_REQUEST',
  'lieuucation/GET_SUCCESS',
  'lieuucation/GET_FAILURE'
)<ILieuucationTimestamped[], ILieuucation[], FeathersError>();

export const getLieuucationById = createAsyncAction(
  'lieuucation/GET_BY_ID_REQUEST',
  'lieuucation/GET_BY_ID_SUCCESS',
  'lieuucation/GET_BY_ID_FAILURE'
)<IGetLieuucationRequest, ILieuucation, FeathersError>();
